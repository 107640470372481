import { computed } from "vue";
import { required, integer, helpers } from "@vuelidate/validators";
const alphaSpace = (value) =>
    !helpers.req(value) || helpers.regex(/^[a-zA-Z\s]*$/);
const rules = computed(() => ({
    SpokeId: {
        required,
        integer,
    },
    SpokeName: {
        required,
        alpha: alphaSpace,
    },
}));

export { rules };
