const selectThemeOverrides = {
    menuBoxShadow:
        "0 6px 16px -9px rgba(0, 0, 0, .08), 0 9px 28px 0 rgba(0, 0, 0, .05), 0 12px 48px 16px rgba(0, 0, 0, .03)",
    peers: {
        InternalSelection: {
            textColor: "#003873",
            placeholderColor: "#7f8686",
            heightMedium: "46px",
            fontSizeMedium: "17px",
        },
    },
};
const inputThemeOverrides = {
    textColor: "#003873",
    placeholderColor: "#7f8686",
    heightMedium: "46px",
    fontSizeMedium: "17px",
};
const buttonThemeOverrides = {
    textColor: "#003873",
    placeholderColor: "#7f8686",
    heightMedium: "48px",
    fontSizeMedium: "17px",
};
const typographyThemeOverrides = {
    pTextColor: "#003873",
};
const railStyle = ({ focused, checked }) => {
    const style = {};
    if (checked) {
        style.background = "#083659";
        if (focused) {
            style.boxShadow = "0 0 0 2px #d0305040";
        }
    } else {
        style.background = "#C4C4C4";
        if (focused) {
            style.boxShadow = "0 0 0 2px #2080f040";
        }
    }
    return style;
};

module.exports = {
    selectThemeOverrides,
    inputThemeOverrides,
    buttonThemeOverrides,
    typographyThemeOverrides,
    railStyle,
};
