import { reactive } from "vue";

const state = reactive({
    SpokeId: 1,
    SpokeName: "New Delhi",
    locations: [],
    submitting: false,
});

export { state };
