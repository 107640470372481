const responses = {
    STORE_RETRIEVED: "store-retrieved",
    CART_RETRIEVED: "cart-retrieved",
    NOT_FOUND: "not-found",
    CART_UPDATED: "cart-updated",
    ITEM_EXISTS: "item-exists",
    INVALID_ITEM: "invalid-item",
    STORE_UPDATED: "store-updated",
    TOKEN_GENERATED: "token-generated",
    UNAUTHORIZED_ACCESS: "unauthorized-access",
    USER_RETRIEVED: "user-retrieved",
    TOKEN_RETRIEVED: "token-retrieved",
    INVALID_FORMAT: "invalid-format",
    SPOKE_RETRIEVED: "spoke-retrieved",
    LOCATIONS_RETRIEVED: "locations-retrieved",
    TOKEN_REVOKED: "token-revoked",
};
module.exports = {
    responses,
};
