import { defineComponent } from "vue";
import LocationPopup from "./components/LocationPopup/View.vue";
import { props } from "@jsRoot/locationPopup/props";

export default defineComponent({
    name: "LocationPopupRoot",
    components: {
        LocationPopup,
    },
    setup() {
        return { props };
    },
});
