const { appOrigin } = require("@settings");
const setSpokeApi = `${appOrigin}/api/set-spoke`;
const getLocationsApi = `${appOrigin}/api/get-locations`;
const theme = require("./theme");

module.exports = {
    setSpokeApi,
    getLocationsApi,
    ...theme,
};
