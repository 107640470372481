<template>
  <div
    class="col-md-1 col-3 text-nowrap pl-0 location-tip-head"
    v-if="Object.keys(state.locations).length"
  >
    <img class="d-inline d-md-none" :src="asset(`img/location-mob.png`)" />
    <img class="d-none d-md-inline" :src="asset(`img/location.png`)" />
    <p
      class="d-inline f-sm-13 proxima-nova-reg m-0 f-16 ml-2"
      v-if="state.SpokeId"
    >
      {{ state.SpokeName }}
      <i class="fas fa-chevron-down f-14" aria-hidden="true"></i>
    </p>
    <div class="location-tip-outer">
      <div class="location-tip">
        <div class="location-scroll">
          <div
            class="location-tip-cities-cover"
            v-for="(cities, location) in state.locations"
            :key="`${location}`"
          >
            <p class="font-weight-bold cursor-default">{{ location }}</p>
            <div
              class="
                d-flex
                flex-column flex-md-row flex-wrap
                location-tip-cities
              "
            >
              <div v-for="(spoke, city) in cities" :key="`${city}-${spoke}`">
                <p
                  class="f-14"
                  :selected="isSelected(spoke, city)"
                  @click="setSpoke(spoke, city)"
                >
                  {{ city }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export { default } from "./Controller";
</script>

<style lang="scss" scoped>
</style>
