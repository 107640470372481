export const NEW_REGISTRATION = "NewRegistration";
export const LOGIN = "Login";
export const CART = "Cart";
export const CHECKOUT = "Checkout";
export const ACCOUNT = "Account";
export const BOOKINGS = "Bookings";
export const REPORTS = "Reports";
export const BOOKING_COMPLETE = "BookingComplete";
export const BOOKING_FAILED = "BookingFailed";
export const BILLING_UPDATE = "BillingUpdate";
