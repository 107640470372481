import { asset } from "@helpers/asset";
import { defineComponent, onMounted, onBeforeUnmount } from "vue";
import {
    NSelect,
    NSpace,
    NAvatar,
    NInput,
    NIcon,
    NButton,
    NInputGroup,
    NSwitch,
    NCheckbox,
    NP,
} from "naive-ui";
import {
    PersonCircleOutline as PersonIcon,
    CallOutline as PhoneIcon,
    MailOutline as MailIcon,
} from "@vicons/ionicons5";

import {
    selectThemeOverrides,
    inputThemeOverrides,
    buttonThemeOverrides,
    typographyThemeOverrides,
    railStyle,
} from "@jsRoot/locationPopup/settings";
import {
    setSpoke,
    fetchLocations,
    isSelected,
    setSelectedAddress,
} from "@jsRoot/locationPopup/actionCreators";

import { state } from "@jsRoot/locationPopup/state";

import { vd } from "@jsRoot/locationPopup/validator";

import { shouldSubmit } from "@jsRoot/locationPopup/watchers";

export default defineComponent({
    name: "LocationPopup",
    components: {
        NSelect,
        NSpace,
        NInput,
        NSwitch,
        NCheckbox,
        NP,
        NIcon,
        NAvatar,
        NButton,
        NInputGroup,
        PersonIcon,
        PhoneIcon,
        MailIcon,
    },
    props: {},
    setup(props) {
        onMounted(() => {
            fetchLocations();
            setSelectedAddress(Bus);
        });
        const Bus = window.bus;
        onBeforeUnmount(() => {
            Bus.off("selected.address", () => {});
        });

        return {
            props,
            state,
            vd,
            shouldSubmit,
            selectThemeOverrides,
            inputThemeOverrides,
            buttonThemeOverrides,
            typographyThemeOverrides,
            railStyle,
            setSpoke,
            fetchLocations,
            isSelected,
            asset,
            Bus,
        };
    },
});
