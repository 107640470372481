import { state } from "@jsRoot/locationPopup/state";
import { setSpokeApi, getLocationsApi } from "@jsRoot/locationPopup/settings";
const { SPOKE_RETRIEVED, LOCATIONS_RETRIEVED } = require("@root/statusCodes");

export const fetchLocations = () => {
    state.submitting = true;
    axios
        .post(getLocationsApi, {})
        .then((response) => {
            if (
                response.data.status === "Success" &&
                response.data.message === LOCATIONS_RETRIEVED &&
                response.data.data
            ) {
                const { locations, SpokeId, SpokeName } = response.data.data;
                state.locations = locations;
                state.SpokeId = SpokeId;
                state.SpokeName = SpokeName;
            }
        })

        .catch((err) => {
            state.submitting = false;
            console.error("DEBUG LOG #err", err);
        });
};

export const setSpoke = async (spoke, city) => {
    if (spoke && city) {
        const fd = new FormData();
        fd.append("SpokeId", spoke);
        fd.append("SpokeName", city);
        state.submitting = true;
        axios
            .post(setSpokeApi, fd)
            .then((response) => {
                if (
                    response.data.status === "Success" &&
                    response.data.message === SPOKE_RETRIEVED &&
                    response.data.data
                ) {
                    window.location.href = window.location.href;
                }
            })

            .catch((err) => {
                state.submitting = false;
                console.error("DEBUG LOG #err", err);
            });
    }
};

export const isSelected = (spoke, city) => {
    return state.SpokeId && state.SpokeName
        ? spoke === state.SpokeId && city === state.SpokeName
            ? true
            : false
        : false;
};

export const setSelectedAddress = (Bus) => {
    Bus.listen("selected.address", ({ ServiceDeliveryCity, SpokeId }) => {
        state.SpokeName = ServiceDeliveryCity;
        state.SpokeId = SpokeId;
    });
};
